import React from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { ModalEnum } from '../../ModalEnum';
import type { VehicleCommon } from '../../model/Vehicle';
import { Logger } from '../../services/Logger';
import Tracking from '../../services/Tracking';
import { openModal } from '../../store/app';
import { addFavoriteVehicle, removeFavoriteVehicle } from '../../store/favoriteVehicles';
import { flashMessageError, flashMessageSuccess } from '../../store/session';

export function useFavoriteToggle(vehicle: VehicleCommon, isSignedIn: boolean) {
  const dispatch: DispatchFunction = useDispatch();

  const handleFavoriteVehicleToggle = useCallback(() => {
    const willBeAdded = !vehicle.is_favorite;
    dispatch(willBeAdded ? addFavoriteVehicle(vehicle.hash) : removeFavoriteVehicle(vehicle.hash))
      .then(() => {
        dispatch(flashMessageSuccess(`favorites.flash.${willBeAdded ? 'added' : 'removed'}`));
      })
      .catch(error => {
        dispatch(flashMessageError(`favorites.flash.error`));
        Logger.default.warning('Favorite vehicles error', { error });
      });
  }, [vehicle]);

  const handleFavoriteClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      if (!isSignedIn) {
        Tracking.track('SEARCH_FAVORITE_TOGGLE_CLICKED', !vehicle.is_favorite, false);
        dispatch(
          openModal(ModalEnum.SIGN_IN, {
            successCallback() {
              handleFavoriteVehicleToggle();
            },
          }),
        );
      } else {
        Tracking.track('SEARCH_FAVORITE_TOGGLE_CLICKED', !vehicle.is_favorite, true);
        handleFavoriteVehicleToggle();
      }
      return true;
    },
    [isSignedIn, handleFavoriteVehicleToggle],
  );

  return [handleFavoriteClick, handleFavoriteVehicleToggle];
}
