import React from 'react';
import { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { useEnum } from '../../hooks/useEnum';
import * as utils from '../../model/Enum';
import {
  getPricePerDayMax,
  getPricePerDayMin,
  getPricePerKmMax,
  getPricePerKmMin,
  getPricePerKmStep,
} from '../../model/FiltrationParameters';
import { countryToCurrency, formatPrice, formatPriceAmount } from '../../model/Price';
import {
  computePricePerDayValue,
  FIRST_REGISTRATION_MAX,
  FIRST_REGISTRATION_MIN,
  FIRST_REGISTRATION_STEP,
  PRICE_PER_DAY_MAX,
  PRICE_PER_DAY_MIN,
  PRICE_PER_DAY_STEP,
  type SearchFilters,
  SEATS_MAX,
  SEATS_MIN,
  SEATS_STEP,
} from '../../model/SearchFilters';
import Tracking from '../../services/Tracking';
import type { ReduxState } from '../../store';
import { isSignedInSelector } from '../../store/selectors';
import { Checkbox } from '../Form/CheckboxField';
import ManufacturerModelSelect from '../Form/ManufacturerModelSelect';
import { RangeSlider } from '../Form/Slider';
import { GiftIcon } from '../Icons/GiftIcon';
import T from '../Translate';

import CheckboxGroup from './CheckboxGroup';

import styles from './filters.module.scss';

interface Props {
  filters: SearchFilters;
  className?: string;

  onChange(filters: Partial<SearchFilters>);
}

const rentConditionsFilters: {
  value: keyof SearchFilters;
  label: string;
  private?: boolean;
}[] = [
  { value: 'instant_booking', label: 'rentCar.list.immediateAccept' },
  { value: 'travel_abroad', label: 'global.travelAbroad' },
  { value: 'favorites', label: 'search.filters.favorite', private: true },
  { value: 'driver_under_25_years', label: 'rentCar.filters.under25years' },
  { value: 'delivery_car', label: 'rentCar.filters.deliveryCar' },
  { value: 'without_vat', label: 'rentCar.filters.vatDeduction' },
  {
    value: 'owner_speaks_english',
    label: 'search.filters.englishSpeakingOwner',
  },
  { value: 'own_insurance', label: 'filters_own_insurance' },
];

const vehicleTypeIconMapping: Record<string, string | React.ReactNode> = {
  cabriolet: 'cabrio',
  caravan: 'caravan',
  coupe: 'coupe',
  estate: 'kombi',
  hatchback: 'hatchback',
  liftback: 'sedan',
  mpv: 'minivan',
  other: 'rocket',
  pickup: 'pickup',
  saloon: 'sedan',
  suv: 'suv',
  tow_truck: 'pickup',
  van: 'van',
  voucher: <GiftIcon className={styles.icon} />,
};

export default function Filters(props: Props) {
  const { filters } = props;
  const { params, lang, region, currency, isSignedIn } = useSelector((state: ReduxState) => ({
    params: state.rentCar.filtration_parameters,
    lang: state.app.language,
    region: state.app.region,
    currency: countryToCurrency(state.app.region),
    isSignedIn: isSignedInSelector(state),
  }));

  const [manufacturersUsedOnly] = useEnum('manufacturersUsedOnly');
  const [bodyTypes] = useEnum('type');
  const [transmissions] = useEnum('transmission');
  const [fuelTypes] = useEnum('fuel');
  const [equipments] = useEnum('equipments');
  const timeout = useRef(null);

  const onChange = (name: keyof SearchFilters, value, useTimeout = false, track?: () => void) => {
    const handler = () => {
      props.onChange({ [name]: value });
      track?.();
    };
    if (useTimeout) {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(handler, 300);
    } else {
      handler();
    }
  };

  const pricePerDayMin = useMemo(() => getPricePerDayMin(region, params), [region, params]);
  const pricePerDayMax = useMemo(() => getPricePerDayMax(region, params), [region, params]);

  return (
    <div className={cn(styles.container, props.className)}>
      <section>
        <T as="h4" className={styles.sectionHeader} id="global.price" />
        <RangeSlider
          className={styles.range}
          label={(min, max) => (
            <div className={styles.rangeLabel}>
              <span>
                {formatPriceAmount(computePricePerDayValue(min, pricePerDayMin, pricePerDayMax), lang, currency, 0)}{' '}
                <T id="global.units.perDay" />
              </span>
              <span>
                {formatPrice(computePricePerDayValue(max, pricePerDayMin, pricePerDayMax), lang, currency, 0)}{' '}
                <T id="global.units.perDay" />
              </span>
            </div>
          )}
          labelAfter
          max={PRICE_PER_DAY_MAX}
          min={PRICE_PER_DAY_MIN}
          onChange={value =>
            onChange('price_per_day', value, true, () => Tracking.track('SEARCH_FILTER_PRICE_PER_DAY_CHANGED', value))
          }
          step={PRICE_PER_DAY_STEP}
          value={filters.price_per_day}
        />
      </section>

      <section>
        <T as="h4" className={styles.sectionHeader} id="global.mileagePriceOverLimit" />
        <RangeSlider
          className={styles.range}
          label={(min, max) => (
            <div className={styles.rangeLabel}>
              <span>{formatPriceAmount(min, lang, currency)} / km</span>
              <span>{formatPrice(max, lang, currency)} / km</span>
            </div>
          )}
          labelAfter
          max={getPricePerKmMax(region, params)}
          min={getPricePerKmMin(region, params)}
          onChange={value =>
            onChange('mileage_price_over_limit', value, true, () =>
              Tracking.track('SEARCH_FILTER_PRICE_PER_KM_CHANGED', value),
            )
          }
          step={getPricePerKmStep(region, params)}
          value={filters.mileage_price_over_limit}
        />
      </section>

      <section>
        <T as="h4" className={styles.sectionHeader} id="rentCar.filters.frequentFilters" />

        <div className={styles.checkboxList}>
          {(isSignedIn ? rentConditionsFilters : rentConditionsFilters.filter(it => !it.private)).map(
            (filter, index) => (
              <div className="pure-checkbox" key={index}>
                <input
                  checked={filters[filter.value] as boolean}
                  id={`rent-conditions-filters-${filter.value}`}
                  name={filter.value}
                  onChange={() => {
                    const checked = !filters[filter.value];
                    onChange(filter.value, checked);
                    Tracking.track('SEARCH_FILTER_FAVORITES_USED', filter.value, checked);
                  }}
                  type="checkbox"
                />
                <label htmlFor={`rent-conditions-filters-${filter.value}`}>
                  <T id={filter.label} />
                </label>
              </div>
            ),
          )}
        </div>
      </section>

      <section>
        <T as="h4" className={styles.sectionHeader} id="global.manufacturer" />
        <ManufacturerModelSelect
          manufacturers={manufacturersUsedOnly}
          model={filters.model}
          modelLabel={<T as="h4" className={styles.sectionHeader} id="global.model" />}
          onChange={filters => props.onChange(filters as Partial<SearchFilters>)}
          options={utils.mapManufacturersToValueLabelPairs(manufacturersUsedOnly, params?.manufacturers)}
          value={filters.manufacturer}
        />
      </section>

      <section>
        <T as="h4" className={styles.sectionHeader} id="global.carType" />
        <CheckboxGroup
          isVehicleType
          name="carType"
          onChange={data => {
            onChange('type', data);
            Tracking.track('SEARCH_FILTER_CAR_TYPE_CHANGED', data);
          }}
          onOptionClick={({ value }, checked) => Tracking.track('SEARCH_FILTER_CAR_TYPE_CLICKED', value, checked)}
          options={bodyTypes
            .map(type => ({
              value: type.value,
              label: type.label,
              icon: vehicleTypeIconMapping[type.value] ?? undefined,
            }))
            .sort((a, b) => {
              if (b.value === 'other') {
                return -1;
              }
              if (a.value === 'other') {
                return 1;
              }
              return a.label.localeCompare(b.label);
            })}
          value={filters.type}
        />
      </section>

      <section>
        <T as="h4" className={styles.sectionHeader} id="global.transmission" />
        <div className={styles.checkboxList}>
          {utils.mapAttributesToValueLabelPairs(transmissions).map(it => (
            <Checkbox
              checked={(filters.transmission ?? []).includes(it.value)}
              key={it.value}
              onChange={event => {
                const data = event.target.checked
                  ? [...filters.transmission, it.value]
                  : filters.transmission.filter(itt => itt !== it.value);
                onChange('transmission', data);
                Tracking.track('SEARCH_FILTER_TRANSMISSION_CHANGED', data);
              }}
              onClick={() => Tracking.track('SEARCH_FILTER_TRANSMISSION_CLICKED', it.value)}
              value={it.value}
            >
              {it.label}
            </Checkbox>
          ))}
        </div>
      </section>

      <section>
        <T as="h4" className={styles.sectionHeader} id="global.fuel" />
        <div className={styles.checkboxList}>
          {utils.mapAttributesToValueLabelPairs(fuelTypes).map(it => (
            <Checkbox
              checked={(filters.fuel ?? []).includes(it.value)}
              key={it.value}
              onChange={event => {
                const data = event.target.checked
                  ? [...filters.fuel, it.value]
                  : filters.fuel.filter(itt => itt !== it.value);
                onChange('fuel', data);
                Tracking.track('SEARCH_FILTER_FUEL_CHANGED', data);
              }}
              onClick={() => Tracking.track('SEARCH_FILTER_FUEL_CLICKED', it.value)}
              value={it.value}
            >
              {it.label}
            </Checkbox>
          ))}
        </div>
      </section>

      <section>
        <T as="h4" className={styles.sectionHeader} id="global.productionYear" />
        <RangeSlider
          className={styles.range}
          label={(min, max) => (
            <div className={styles.rangeLabel}>
              <span>{min}</span>
              <span>{max}</span>
            </div>
          )}
          labelAfter
          max={params?.registration_date_extreme_max ?? FIRST_REGISTRATION_MAX}
          min={params?.registration_date_extreme_min ?? FIRST_REGISTRATION_MIN}
          onChange={value =>
            onChange('first_registration', value, true, () =>
              Tracking.track('SEARCH_FILTER_MANUFACTURE_YEAR_CHANGED', value),
            )
          }
          step={params?.registration_date_extreme_step ?? FIRST_REGISTRATION_STEP}
          value={filters.first_registration}
        />
      </section>

      <section>
        <T as="h4" className={styles.sectionHeader} id="global.seats" />
        <RangeSlider
          className={styles.range}
          label={(min, max, isMax) => (
            <div className={styles.rangeLabel}>
              <span>{min}</span>
              <span>{`${max}${isMax ? '+' : ''}`}</span>
            </div>
          )}
          labelAfter
          max={SEATS_MAX}
          min={SEATS_MIN}
          onChange={value => onChange('seats', value, true, () => Tracking.track('SEARCH_FILTER_SEATS_CHANGED', value))}
          step={SEATS_STEP}
          value={filters.seats}
        />
      </section>

      <section>
        <T as="h4" className={styles.sectionHeader} id="global.equipment" />
        <CheckboxGroup
          name="equipment"
          onChange={data => {
            onChange('equipment', data);
            Tracking.track('SEARCH_FILTER_EQUIPMENT_CHANGED', data);
          }}
          onOptionClick={({ value }, checked) => Tracking.track('SEARCH_FILTER_EQUIPMENT_CLICKED', value, checked)}
          options={utils.mapEquipmentsToValueLabelPairs(equipments, params?.equipment)}
          value={filters.equipment}
        />
      </section>
    </div>
  );
}
