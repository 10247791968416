import React from 'react';

interface Props {
  className?: string;
}

export function GiftIcon(props: Props) {
  return (
    <div className={props.className}>
      <svg version="1.1" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.486,15.179l-1.154,0c-0.654,0 -1.183,-0.529 -1.183,-1.183l-0,-5.833c-0,-0.653 0.529,-1.183 1.183,-1.183l2.698,0c-0.249,-0.536 -0.381,-1.124 -0.381,-1.726c-0,-1.088 0.432,-2.13 1.2,-2.899c0.769,-0.769 1.812,-1.201 2.899,-1.201c2.056,0 3.548,1.132 4.57,2.542c0.258,0.356 0.484,0.728 0.681,1.099c0.197,-0.371 0.423,-0.743 0.681,-1.099c1.022,-1.41 2.514,-2.542 4.57,-2.542c1.087,0 2.13,0.432 2.899,1.201c0.769,0.769 1.201,1.811 1.201,2.899c-0,0.602 -0.133,1.19 -0.382,1.726l2.697,0c0.653,0 1.183,0.53 1.183,1.183l-0,5.833c-0,0.654 -0.53,1.183 -1.183,1.183l-1.147,0l-0,10.488c-0,0.653 -0.53,1.183 -1.183,1.183l-18.667,-0c-0.653,-0 -1.182,-0.53 -1.182,-1.183l-0,-10.488Zm5.018,-5.833c-2.881,-0 -4.99,-0 -4.99,-0c0,-0 0,3.468 0,3.468c0,-0 4.483,-0 9.303,-0l0,-3.461l-4.069,0c-0.082,0 -0.163,-0.002 -0.244,-0.007Zm4.313,5.833l-6.966,0c0,2.74 0,9.305 0,9.305l6.966,-0l0,-9.305Zm-0.412,-8.199c-0.226,-0.587 -0.552,-1.274 -1.002,-1.895c-0.603,-0.831 -1.444,-1.565 -2.655,-1.565c-0.46,-0 -0.901,0.183 -1.226,0.508c-0.325,0.325 -0.508,0.766 -0.508,1.226c0,0.459 0.183,0.9 0.508,1.226c0.288,0.287 0.666,0.463 1.067,0.5l3.816,0Zm7.004,0c0.401,-0.037 0.779,-0.213 1.067,-0.5c0.325,-0.326 0.508,-0.767 0.508,-1.226c-0,-0.46 -0.183,-0.901 -0.508,-1.226c-0.325,-0.325 -0.766,-0.508 -1.226,-0.508c-1.211,-0 -2.052,0.734 -2.654,1.565c-0.451,0.621 -0.777,1.308 -1.003,1.895l3.816,0Zm2.743,8.199l-6.969,0l-0,9.305l6.969,-0l0,-9.305Zm-6.969,-2.365c4.819,-0 9.299,-0 9.299,-0l0,-3.468c0,-0 -2.108,-0 -4.988,-0c-0.081,0.005 -0.162,0.007 -0.244,0.007l-4.067,0l-0,3.461Z" />
      </svg>
    </div>
  );
}
