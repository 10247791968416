import React from 'react';
import cn from 'classnames';

import styles from './checkboxGroup.module.scss';

interface Option<T> {
  value: T;
  label: string;
  icon?: string | React.ReactNode;
}

interface Props<T = string> {
  value: T[];
  options: Option<T>[];
  name: string;
  className?: string;
  isVehicleType?: boolean;

  onOptionClick?(option: Option<T>, checked: boolean): void;

  onChange(value: T[]): void;
}

export default function CheckboxGroup<T = string>(props: Props<T>) {
  return (
    <div className={cn(styles.options, { 'vehicle-type': props.isVehicleType })}>
      {props.options.map((option, index) => (
        <label
          className={cn(styles.option, {
            checked: props.value.includes(option.value),
          })}
          key={index}
        >
          <input
            checked={props.value.includes(option.value)}
            name={`${props.name}[${index}]`}
            onChange={event => {
              const newValue = [...props.value];
              if (event.target.checked) {
                newValue.push(option.value);
              } else {
                newValue.splice(newValue.indexOf(option.value), 1);
              }
              props.onOptionClick?.(option, event.target.checked);
              return props.onChange(newValue);
            }}
            type="checkbox"
            value={option.value.toString()}
          />
          {props.isVehicleType ? (
            typeof option.icon === 'object' ? (
              option.icon
            ) : (
              <i className={`icon icon-${option.icon ? option.icon : option.value}`} />
            )
          ) : null}
          <span>{option.label}</span>
        </label>
      ))}
    </div>
  );
}
