import React from 'react';

import { StaticImage } from '../Image';
import T from '../Translate';

import styles from './longTermDiscountBanner.module.scss';

export default function LongTermDiscountBanner() {
  return (
    <div className={styles.container}>
      <StaticImage className={styles.image} name="cash hand" />
      <div className={styles.content}>
        <T as="h2" html id="longTermDiscountBanner.title" />
        <div className={styles.contentWrapper}>
          <T as="p" id="longTermDiscountBanner.description" />
          <div className={styles.variants}>
            <div className={styles.item}>
              <T id="longTermDiscountBanner.0.days" />
              <T className={styles.label} id="longTermDiscountBanner.0.discount" />
            </div>
            <div className={styles.item}>
              <T id="longTermDiscountBanner.1.days" />
              <T className={styles.label} id="longTermDiscountBanner.1.discount" />
            </div>
            <div className={styles.item}>
              <T id="longTermDiscountBanner.2.days" />
              <T className={styles.label} id="longTermDiscountBanner.2.discount" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
