import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useRouteContext } from '../RouteProvider';
import { AppState } from '../services/AppState';
import type { ReduxState } from '../store';
import PageLayout from '../views/Layout/Page';
import RentCar from '../views/Search/SearchPage';

export default function RentCarPage() {
  const dispatch: DispatchFunction = useDispatch();
  const state = useSelector((store: ReduxState) => ({
    state: store.rentCar,
    user: store.user,
    region: store.app.region,
  }));
  const { location, navigate, generatePath } = useRouteContext();

  return (
    <PageLayout pageview="search_list" requiredState={AppState.LOADED}>
      <RentCar location={location} {...state} dispatch={dispatch} generatePath={generatePath} navigate={navigate} />
    </PageLayout>
  );
}
