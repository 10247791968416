import React from 'react';
import { useSelector } from 'react-redux';
import type { HLocation } from '@reach/router';
import cn from 'classnames';
import { v4 as uuid } from 'uuid';

import { range } from '../../helpers';
import {
  computeNextFilters,
  filtersToQueryString,
  hasChanged,
  queryStringToFilters,
  type SearchFilters,
} from '../../model/SearchFilters';
import type { Trip } from '../../model/Trip';
import type { User } from '../../model/User';
import { getVehicleDistanceFromGPS } from '../../model/VehicleLocation';
import type { RouteContext } from '../../RouteProvider';
import type { Country } from '../../services/Country';
import Tracking from '../../services/Tracking';
import type { ReduxState } from '../../store';
import { fetchVehicles, type RentCarState } from '../../store/rentCar';
import { isSignedInSelector } from '../../store/selectors';
import { flashMessageError } from '../../store/session';
import Button from '../Button';
import DotIcon from '../DotIcon';
import { useFavoriteToggle } from '../Favorites/useFavoriteToggle';
import Loader from '../Loader';
import { Sprite, SpriteName } from '../Sprite';
import T from '../Translate';
import { CarCardLarge, CarCardSkeletonLarge } from '../Vehicle/CarCard';
import CarDetailLink from '../Vehicle/CarDetailLink';

import Filters from './Filters';
import LongTermDiscountBanner from './LongTermDiscountBanner';
import Map, { MapMarkerStateProvider, useMapMarkerState } from './Map';
import SearchBar from './SearchBar';
import SearchMetaTags from './SearchMetaTags';
import OrderingSelect from './SearchOrder';

import styles from './searchPage.module.scss';

type Props = {
  user?: User;
  state: RentCarState;
  location: HLocation;
  region: Country;
  dispatch: DispatchFunction;
  navigate: RouteContext<UnsafeAny>['navigate'];
  generatePath: RouteContext<UnsafeAny>['generatePath'];
};

const FETCH_TIMEOUT = 500;

export default class RentCar extends React.Component<Props> {
  state = {
    mapOpened: false,
    filtersOpened: false,
    loading: false,
  };

  loading = null;

  private get filters(): SearchFilters {
    return this.parseQueryString(window.location.search);
  }

  public componentDidMount() {
    const parsed = this.filters;
    if (this.props.state.data && !hasChanged(parsed, this.props.state.filters)) {
      return;
    }
    this.loadResults(parsed);
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.user?.hash !== this.props.user?.hash || this.props.region !== prevProps.region) {
      this.props.dispatch(fetchVehicles(this.filters));
      return;
    }
    if (this.props.location.search !== prevProps.location.search) {
      try {
        const parsed = this.filters;
        if (!hasChanged(parsed, this.props.state.filters)) {
          return;
        }
        Tracking.track('SEARCH_FILTERS_CHANGED', parsed);
        this.loadResults(parsed);
      } catch (error) {
        this.props.dispatch(flashMessageError(error.message));
      }
    }
  }

  private getQueryString(filters: Partial<SearchFilters>): string {
    const query = filtersToQueryString(filters, this.props.state.initialFilters);
    return `?${query ?? ''}`;
  }

  private parseQueryString(query: string) {
    query = query?.replace(/\?/, '') ?? '';
    return queryStringToFilters(query, this.props.state.initialFilters);
  }

  private loadResults = (filters: Partial<SearchFilters> = {}) => {
    if (this.loading) {
      clearTimeout(this.loading);
    }
    this.loading = setTimeout(async () => {
      filters.country_code = this.props.region;
      await this.props.dispatch(fetchVehicles(filters));
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, FETCH_TIMEOUT);
  };

  private loadMoreResults = async () => {
    if (this.state.loading) return;

    this.setState(prev => ({ ...prev, loading: true }));
    await this.props.dispatch(fetchVehicles({}, this.props.state.data.page + 1));
    this.setState(prev => ({ ...prev, loading: false }));
  };

  private onFiltersChange = (filters: Partial<SearchFilters>, changeKey?: boolean) => {
    try {
      if (changeKey) {
        filters.key = uuid();
      }
      const currentFilters = this.filters;
      const resolvedFilters = computeNextFilters(currentFilters, filters);
      if (!hasChanged(resolvedFilters, currentFilters)) {
        return;
      }
      Tracking.track('SEARCH_FILTERS_CHANGED', resolvedFilters);
      const query = this.getQueryString(resolvedFilters);
      window.history.pushState(null, '', query);
      this.loadResults(resolvedFilters);
    } catch (error) {
      this.props.dispatch(flashMessageError(error.message));
    }
  };

  private onResetClick = () => {
    Tracking.track('SEARCH_FILTERS_CLEARED');
    this.onFiltersChange(this.props.state.initialFilters, true);
  };

  private toggleMap = () => {
    this.setState({ mapOpened: !this.state.mapOpened });
    Tracking.track('SEARCH_MAP_TOGGLED');
  };

  private toggleFilters = () => {
    this.setState({ filtersOpened: !this.state.filtersOpened });
    Tracking.track('SEARCH_FILTERS_TOGGLED');
  };

  public render() {
    const { state } = this.props;
    const filters = this.filters;
    const changed = hasChanged(filters, state.initialFilters);

    return (
      <main className="main-container">
        <SearchMetaTags />

        <div className={styles.container}>
          <div className={styles.searchbarContainer}>
            <SearchBar
              className={styles.searchbar}
              dateFrom={filters.date_from}
              dateTo={filters.date_to}
              key={filters.key}
              location={filters.location}
              onSubmit={this.onFiltersChange}
            />
            <LongTermDiscountBanner />
          </div>

          <div className={styles.content}>
            <div
              className={cn(styles.filters, {
                opened: this.state.filtersOpened,
              })}
            >
              <div className={styles.filtersHeader}>
                <T as="h2" id="search.filters.title" />
                <Button onClick={this.toggleFilters} sm>
                  <i className="icon icon-close" />
                </Button>
              </div>
              <div className={styles.filtersContent}>
                <Filters className={styles.filtersBody} filters={filters} onChange={this.onFiltersChange} />

                <div className={styles.filtersFooter}>
                  <Button lg onClick={this.toggleFilters} variant="secondary">
                    {state.loading ? (
                      <Loader dotClassName="bg-white" />
                    ) : (
                      <T data={{ count: state.data?.total_items }} id="search.filters.applyButton" />
                    )}
                  </Button>
                </div>
              </div>
              <div className={styles.filtersBackdrop} onClick={this.toggleFilters} />
            </div>

            <MapMarkerStateProvider>
              <div className={styles.main}>
                <div className={styles.buttons}>
                  <Button className={styles.mapButton} icon onClick={this.toggleMap}>
                    <i className="icon icon-map" />
                    <T id="search.map.open" />
                  </Button>

                  <Button className={styles.filtersButton} onClick={this.toggleFilters}>
                    <Sprite name={SpriteName.FILTER} />
                    <T id="search.filters.open" />
                  </Button>

                  <OrderingSelect onChange={order => this.onFiltersChange({ order })} value={filters.order} />

                  {changed ? (
                    <Button onClick={this.onResetClick} type="reset" variant="link">
                      <T id="search.filters.reset" />
                    </Button>
                  ) : null}
                </div>

                {state.error ? <DotIcon icon="round-warning" text={state.error} /> : null}
                {state.loading ? (
                  <div className={styles.emptyList}>
                    <div className={styles.items}>
                      {range(0, 10).map(index => (
                        <CarCardSkeletonLarge className={styles.card} key={index} />
                      ))}
                    </div>
                  </div>
                ) : null}
                {!state.loading && state.data ? (
                  <div className={styles.list}>
                    <div className={styles.itemsCount}>
                      <T data={{ count: state.data.total_items }} id="search.list.count" />
                    </div>

                    <div className={styles.items}>
                      {state.data.items.map(trip => (
                        <SearchItem key={`result-${trip.hash}`} trip={trip} />
                      ))}
                    </div>

                    {state.data.has_next ? (
                      <Button className={styles.loadMore} onClick={this.loadMoreResults} variant="mono">
                        <T id="search.loadMore" />
                        {this.state.loading ? <Loader /> : <i className="icon icon-arrow-right-circle" />}
                      </Button>
                    ) : null}
                  </div>
                ) : null}
              </div>

              <div className={cn(styles.map, { opened: this.state.mapOpened })}>
                <div className={styles.mapHeader}>
                  <T as="h2" id="search.map.title" />
                  <Button onClick={this.toggleMap} sm>
                    <i className="icon icon-close" />
                  </Button>
                </div>
                <Map className={styles.mapContent} data={state.data} />
                <div className={styles.mapBackdrop} onClick={this.toggleMap} />
              </div>
            </MapMarkerStateProvider>
          </div>
        </div>
      </main>
    );
  }
}

type ItemProps = {
  trip: Trip;
};

function SearchItem({ trip }: ItemProps) {
  const { location, isSignedIn } = useSelector((store: ReduxState) => ({
    location: store.rentCar.filters.location,
    isSignedIn: isSignedInSelector(store),
  }));
  const distance = getVehicleDistanceFromGPS(trip.vehicle, location);
  const ctx = useMapMarkerState();

  const handleMouseMove = (inside: boolean) => () => {
    ctx.setHovered(inside ? trip.hash : null);
  };

  const [handleFavoriteClick] = useFavoriteToggle(trip.vehicle, isSignedIn);

  return (
    <CarDetailLink
      bodyType={trip.vehicle.type}
      className={styles.item}
      fuel={trip.vehicle.fuel}
      hash={trip.vehicle.hash}
      manufacturer={trip.vehicle.manufacturer}
      model={trip.vehicle.model}
      onMouseEnter={handleMouseMove(true)}
      onMouseLeave={handleMouseMove(false)}
      target={process.env.NODE_ENV === 'production' ? '_blank' : undefined}
    >
      <CarCardLarge
        averageRating={trip.vehicle.rating_average_float}
        className={styles.card}
        discount={trip.pricing.discount}
        distance={distance}
        favorite={!!trip.vehicle.is_favorite}
        image={trip.vehicle.main_image}
        kmIncluded={trip.total_mileage}
        name={trip.vehicle.name}
        noOfDays={trip.trip_length}
        onFavoriteClick={handleFavoriteClick}
        pricePerDay={trip.pricing.price_per_day}
        pricePerKm={trip.vehicle.prices.price_per_km}
        pricePerOneDay={trip.pricing.price_per_one_day}
        ratingsCount={trip.vehicle.ratings_count}
        showPricePerOneDay={trip.pricing.price_per_one_day.amount !== trip.pricing.price_per_day.amount}
        tags={trip.vehicle.tags}
        totalPrice={trip.price}
      />
    </CarDetailLink>
  );
}
