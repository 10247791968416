import React from 'react';
import { useCallback, useMemo } from 'react';

import { toArray } from '../../helpers';
import {
  type Manufacturer,
  mapModelsToValueLabelPairs,
  type ValueLabelPair,
  type VehicleModel,
} from '../../model/Enum';
import Tracking from '../../services/Tracking';

import Select from './Select';

interface Props {
  value: Manufacturer['id'] | Manufacturer['id'][];
  model?: VehicleModel['model_id'];

  options: ValueLabelPair<Manufacturer['id']>[];
  manufacturers?: Manufacturer[];
  modelLabel: React.ReactNode;

  onChange(value: { manufacturer: number | number[]; model: number }): void;
}

const normalize = str =>
  str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

const filterOption = (option, inputValue) => {
  const { label } = option;

  return label.split(' ').some(labelSplit => normalize(labelSplit).startsWith(normalize(inputValue)));
};

export default function ManufacturerModelSelect({
  value,
  model,
  options,
  manufacturers,
  modelLabel,
  onChange,
  ...props
}: Props) {
  const manufacturer: Manufacturer = useMemo(() => {
    if (!value || !Array.isArray(value) || value.length !== 1) return null;
    return manufacturers.find(m => m.id === value[0]);
  }, [manufacturers, value]);

  const models = mapModelsToValueLabelPairs(manufacturer?.vehicle_models ?? []);

  const onManufacturerChange = useCallback(
    nextManufacturers => {
      onChange({ manufacturer: nextManufacturers, model: undefined });
      const values = toArray(nextManufacturers).map(it => manufacturers.find(itt => itt.id === it));
      Tracking.track('SEARCH_FILTER_MANUFACTURER_CHANGED', values);
    },
    [onChange],
  );

  return (
    <>
      <Select
        filterOption={filterOption}
        isMulti
        onChange={onManufacturerChange}
        options={options}
        value={value}
        {...props}
      />

      {manufacturer !== null && (
        <>
          {modelLabel}
          <Select
            filterOption={filterOption}
            isClearable
            onChange={(model: number) => {
              onChange({
                manufacturer: value,
                model,
              });
              Tracking.track(
                'SEARCH_FILTER_MODEL_CHANGED',
                models.find(m => m.value === model),
              );
            }}
            options={models}
            value={model}
            {...props}
          />
        </>
      )}
    </>
  );
}
