import React from 'react';
import cn from 'classnames';

import { useTranslation } from '../Translate';

import styles from './simpleSelect.module.scss';

interface Option<T> {
  value: T;
  label: string;
}

interface SimpleSelectProps<T extends string> {
  value: T;
  options: Option<T>[];
  className?: string;

  onChange(value: T): void;

  onOptionClick?(option: Option<T>): void;
  onFocus?(): void;
}

export function SimpleSelect<T extends string>(props: SimpleSelectProps<T>) {
  const { t } = useTranslation();

  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value as T;
    props.onChange(value);
  };

  return (
    <label className={cn(styles.select, props.className)}>
      <select onChange={handleSelect} onFocus={props.onFocus} value={props.value}>
        {props.options.map(it => (
          <option key={it.value} onClick={() => props.onOptionClick?.(it)} value={it.value}>
            {t(it.label, { default: it.label })}
          </option>
        ))}
      </select>
    </label>
  );
}
