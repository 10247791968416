import React, { PureComponent, useContext, useState } from 'react';
import cn from 'classnames';
import GoogleMapReact from 'google-map-react';

import config from '../../config';
import type { SearchResults } from '../../model/Search';
import type { Trip } from '../../model/Trip';
import { getVehicleParking } from '../../model/VehicleLocation';
import Tracking from '../../services/Tracking';
import Price from '../Price';
import CarCardSmall from '../Vehicle/CarCard';
import CarDetailLink from '../Vehicle/CarDetailLink';

import styles from './map.module.scss';

const DEFAULT_ZOOM = 7;

interface Props {
  data: SearchResults;
  className?: string;
}

export default class Map extends PureComponent<Props> {
  state = {
    apiLoaded: false,
    center: { lat: 49.8037633, lng: 15.4749126 },
    zoom: DEFAULT_ZOOM,
    opened: null,
  };

  maps: UnsafeAny;
  map: UnsafeAny;

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    const prevData = prevProps.data;

    if (data !== prevData && data?.items && this.state.apiLoaded) {
      this.fitBounds();
    }
  }

  handleApiLoaded = ({ map, maps }) => {
    if (!map || !maps) {
      this.setState({ apiLoaded: false });
      return;
    }

    this.map = map;
    this.maps = maps;

    this.setState({ apiLoaded: true });

    this.fitBounds();
  };

  fitBounds = () => {
    const { data } = this.props;

    if (!data || !data.items) {
      return;
    }

    const bounds = new this.maps.LatLngBounds();
    for (const item of data.items) {
      const location = getVehicleParking(item.vehicle.locations);
      if (!location) {
        continue;
      }
      const itemPosition = new this.maps.LatLng(location.lat, location.lon);
      bounds.extend(itemPosition);
    }
    this.map.fitBounds(bounds);
  };

  _onChange = ({ center, zoom }) => {
    this.setState({
      center,
      zoom,
    });
  };

  focusMarker = (hash_code, center) => this.setState({ center, opened: hash_code });

  handleMapClick = () => this.state.opened !== null && this.setState({ opened: null });

  render() {
    const { data, className } = this.props;

    return (
      <div className={cn(styles.mapContainer, className)} onClick={this.handleMapClick}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: config.googleApiKey }}
          center={this.state.center}
          onChange={this._onChange}
          onGoogleApiLoaded={this.handleApiLoaded}
          yesIWantToUseGoogleMapApiInternals
          zoom={this.state.zoom}
        >
          {data?.items?.map((trip, index) => {
            const location = getVehicleParking(trip.vehicle.locations);

            if (!location) return null;

            return (
              <Marker
                key={index}
                lat={location.lat}
                lng={location.lon}
                onClick={() =>
                  this.focusMarker(trip.hash, {
                    lat: location.lat,
                    lgn: location.lon,
                  })
                }
                selected={this.state.opened === trip.hash}
                trip={trip}
              />
            );
          })}
        </GoogleMapReact>
      </div>
    );
  }
}

interface MapMarkerStateContext {
  hovered: string | null;

  setHovered(id: string): void;
}

const context = React.createContext<MapMarkerStateContext>(null);

export function useMapMarkerState() {
  return useContext(context);
}

export function MapMarkerStateProvider(props: React.PropsWithChildren<UnsafeAny>) {
  const [state, setState] = useState<MapMarkerStateContext['hovered']>(null);

  const ctx: MapMarkerStateContext = {
    hovered: state,
    setHovered(id: string) {
      setState(id);
    },
  };

  return <context.Provider value={ctx}>{props.children}</context.Provider>;
}

interface MarkerProps {
  trip: Trip;
  selected?: boolean;
  lat: number;
  lng: number;

  onClick(): void;
}

export function Marker({ trip, onClick, selected }: MarkerProps) {
  const ctx = useMapMarkerState();

  if (selected) {
    return (
      <div className="marker-arrow marker-container-open">
        <CarDetailLink
          bodyType={trip.vehicle.type}
          fuel={trip.vehicle.fuel}
          hash={trip.vehicle.hash}
          manufacturer={trip.vehicle.manufacturer}
          model={trip.vehicle.model}
          target="_blank"
        >
          <CarCardSmall
            averageRating={trip.vehicle.rating_average_float}
            image={trip.vehicle.main_image}
            name={trip.vehicle.name}
            noOfDays={trip.trip_length}
            pricePerDay={trip.pricing.price_per_day}
            pricePerKm={trip.vehicle.prices.price_per_km}
            pricePerOneDay={trip.pricing.price_per_one_day}
            ratingsCount={trip.vehicle.ratings_count}
            totalPrice={trip.price}
          />
        </CarDetailLink>
      </div>
    );
  }

  return (
    <div
      className={cn(styles.marker, { hovered: ctx.hovered === trip.hash })}
      onClick={() => {
        Tracking.track('SEARCH_MAP_CARD_CLICKED');
        onClick();
      }}
    >
      <Price price={trip.price} />
    </div>
  );
}
