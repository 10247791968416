import React, { useCallback, useMemo } from 'react';

import { ORDER_BY, ORDER_TYPE } from '../../model/SearchFilters';
import Tracking from '../../services/Tracking';
import { SimpleSelect } from '../Form/SimpleSelect';

import styles from './searchPage.module.scss';

const options = [
  {
    label: 'global.orderBy.recommended',
    value: `${ORDER_BY.SMART_SEARCH_POINTS}:${ORDER_TYPE.DESC}`,
  },
  {
    label: 'global.orderBy.pricePerDayDesc',
    value: `${ORDER_BY.PRICE_PER_DAY}:${ORDER_TYPE.DESC}`,
  },
  {
    label: 'global.orderBy.pricePerDayAsc',
    value: `${ORDER_BY.PRICE_PER_DAY}:${ORDER_TYPE.ASC}`,
  },
  {
    label: 'global.orderBy.distance',
    value: `${ORDER_BY.DISTANCE}:${ORDER_TYPE.ASC}`,
  },
];

export default function SearchOrder({ value, onChange }) {
  const currentValue = useMemo(() => `${value.by}:${value.type}`, [value]);

  const onSelect = useCallback(
    value => {
      const [by, type] = value.split(':');
      Tracking.track('SEARCH_ORDER_CHANGED', value);
      onChange({ by, type });
    },
    [onChange],
  );

  return (
    <SimpleSelect
      className={styles.order}
      onChange={onSelect}
      onFocus={() => Tracking.track('SEARCH_ORDER_OPENED')}
      options={options}
      value={currentValue}
    />
  );
}
